import { get } from 'axios'

export default {
  async fetchItems ({ commit }, status = '') {
    commit('SET_ITEMS', [])
    const url = `items/closet?status=${status}`
    const { data } = await get(url)
    commit('SET_ITEMS', data)
  }
}
