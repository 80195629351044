<template>
  <div>
    <router-view></router-view>
    <div class="overlay"></div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  methods: {
    ...mapActions('user', ['getFromStorage'])
  },
  async created () {
    await this.getFromStorage()
  }
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
