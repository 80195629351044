/* eslint-disable camelcase */
import axios, { post } from 'axios'

export default {
  async login ({ commit }, credentials) {
    try {
      const url = 'auth/login'
      const { data } = await post(url, credentials)
      const { user, access_token } = data
      axios.defaults.headers.Authorization = `Bearer ${access_token}`
      commit('SET_USER', user)
      localStorage.setItem('access_token', access_token)
      localStorage.setItem('user', JSON.stringify(user))
      return true
    } catch (e) {
      return e.response ? e.response : e
    }
  },

  async logout ({ commit }, credentials) {
    try {
      const url = 'auth/logout'
      await post(url, credentials)
      return true
    } catch (_) {
      return false
    } finally {
      commit('SET_USER', {})
      localStorage.setItem('access_token', '')
      window.location.href = '/'
    }
  },

  getFromStorage ({ commit }) {
    const localUser = localStorage.getItem('user')
    const localAccessToken = localStorage.getItem('access_token')

    if (!localUser || !localAccessToken) return false
    const user = JSON.parse(localUser)
    axios.defaults.headers.Authorization = `Bearer ${localAccessToken}`
    commit('SET_USER', user)
    return true
  }
}
