import axios from 'axios'

export default {
  async fetch ({ commit, getters }) {
    const { items } = getters
    if (items.length) return
    const { data } = await axios.get('items')
    commit('SET_ITEMS', data)
    return true
  },

  async fetchByCategory ({ commit, getters }, category) {
    const { lastCategoryItem } = getters
    if (lastCategoryItem === category) return
    const url = `items/category/${category}`
    const { data } = await axios.get(url)
    console.log(data)
  }
}
